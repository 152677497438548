import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";

export type BackButtonProps = {
    /**
     * The target navigation link
     */
    to?: string;
}

export const BackButton = (props: BackButtonProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    console.log(props.to);
    if (props.to) {

        return <NavLink className="backbutton" to={props.to!}>
            <i className="icon icon-chevron-left"></i>
            {t('BACK', { ns: "common" })}
        </NavLink>;

    } else {

        return <a className="backbutton" onClick={() => navigate(-1)}>
            <i className="icon icon-chevron-left"></i>
            {t('BACK', { ns: "common" })}
        </a>
    }
}